<template>
    <div>
        <b-sidebar id="sidebar-suppRowData" :show="showSidebar" sidebar-class="sidebar-lg" bg-variant="white" shadow
            backdrop no-header right>
            <template #default="{ hide }">
                <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
                    <h4 class="mb-0">
                        Edit {{ form.super_supp ? form.super_supp.name : '' }}
                    </h4>
                    <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
                </div>

                <b-form @submit="onSubmit" class="m-2" form="FORMID">

                   Your Supplier ID is  ( {{ form.super_supp_id }} )

                    <b-form-group id="note" label="Note:" label-for="input-note">
                        <b-form-input id="input-note" v-model="form.note" placeholder="Enter note"></b-form-input>
                    </b-form-group>

                    <b-form-group id="priority" label="Priority:" label-for="input-priority">
                        <b-form-input id="input-priority" v-model="form.priority" placeholder="Enter priority"
                            required></b-form-input>
                    </b-form-group>

                    <b-form-group id="cost" Label="Cost" label-for="input-cost">
                        <b-form-input id="input-cost" v-model="form.cost" type="number" placeholder="Enter cost" required
                            min="0" step="0.01"></b-form-input>
                    </b-form-group>


                    <b-form-group id="partnumber" label="Part Number:" label-for="input-partnumber">
                        <b-form-input id="input-partnumber" v-model="form.partnumber" placeholder="Enter Part Number"
                            required></b-form-input>
                    </b-form-group>

                    <b-form-checkbox v-model="isTaxInclude">
                        Include Tax?
                    </b-form-checkbox>

                    <div class="mt-1">
                        <span>Total Price : {{ totalPrice }}</span>
                    </div>

                    <!-- <div class="mt-4">
                    <b-button type="submit" variant="primary" @click="hide" class="mr-2">Submit</b-button>
                    <b-button variant="outline-danger" @click="hide">Cancel</b-button>
                    </div> -->

                </b-form>
            </template>

            <template #footer>
                <div class="flex mb-2 ml-2">
                    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" form="FORMID" variant="success" class="mr-2"
                        type="submit" @click="onSubmit">
                        Submit
                    </b-button>

                    <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="outline-danger" form="FORMID"
                        @click="closeSidebar">
                        Cancel
                    </b-button>
                </div>
            </template>

        </b-sidebar>
    </div>
</template>

<script>
import {
    BSidebar, BForm, BFormGroup, BFormInput, BFormTextarea, BButton, BBadge, BFormCheckbox, BAvatar,
    VBToggle
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
    components: {
        BForm, BFormGroup, BFormInput, BFormTextarea, BButton, BBadge, BSidebar,
        BFormCheckbox, BAvatar, VBToggle
    },
    directives: {
        'b-toggle': VBToggle,
        Ripple,
    },
    watch: {
        suppRowData() {
            this.form = this.suppRowData;
        }
    },

    props: {
        suppRowData: {
            type: Object
        },
    },

    data() {
        return {
            isTaxInclude: false,
            form: {
                super_supp: {
                    name: '' // Provide a default value for 'name'
                }
            },
        }
    },

    methods: {
        closeSidebar() {
            this.$root.$emit('bv::toggle::collapse', 'sidebar-suppRowData')
        },
        onSubmit(event) {
            event.preventDefault()

            if (this.checkValid) {
                if (this.isTaxInclude) {
                    const tax = 0.1 * parseInt(this.form.cost)
                    const tPrice = parseInt(this.form.cost) + tax

                    this.form.totalcost = tPrice.toString()
                }
                else {
                    this.form.totalcost = this.form.cost
                }

                axios({
                    method: 'patch',
                    url: process.env.VUE_APP_API + '/supplier/' + this.form.id,
                    data: this.form
                })
                    .then(res => {
                        if (res.data.success == true) {
                            this.$emit('refresh')
                            this.closeSidebar()
                            this.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: 'Success',
                                    icon: 'AlertOctagonIcon',
                                    text: 'supplier edited succesfully',
                                    variant: 'success',
                                },
                            },
                                {
                                    position: 'top-right'
                                })

                        }
                        else if (res.data.success == false) {
                            this.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: 'Failure',
                                    icon: 'AlertOctagonIcon',
                                    text: 'supplier could not edited ',
                                    variant: 'danger',
                                },
                            },
                                {
                                    position: 'top-right'
                                })
                        }
                    })
            }
            else {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Validation Error',
                        icon: 'AlertOctagonIcon',
                        text: 'Please fill all the required fields',
                        variant: 'danger',
                    },
                },
                    {
                        position: 'top-left'
                    })
            }
        }
    },
    computed: {
        checkValid: function () {
            return this.form.cost.length != 0 && this.form.priority.length != 0 && this.form.partnumber.length != 0
        },
        totalPrice: function () {
            if (this.isTaxInclude) {
                const tax = 0.1 * parseInt(this.form.cost)
                const tPrice = parseInt(this.form.cost) + tax

                return tPrice.toString()
            }
            else {
                return this.form.cost
            }
        }
    }
}
</script>

<style></style>